import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

const styles = theme => ({
  loadingMessage: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    background: 'rgba(0,0,0,0.5)',
    left:'0',
    top: '0',
    'z-index': '999'

  },
  loadingimg: {
   
    width: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)'
  }
});
const loadingsvg = require("../../images/puff.svg");

function Loading(props) {
  const { classes, loading } = props;
  return (
    <div style={loading ? { display: 'block' } : { display: 'none' }} className={classes.loadingMessage}>
      <img
        className={classes.loadingimg}
        src={loadingsvg}
        alt="icono"
      ></img>

      <Typography variant="h6">
        Waiting for input
      </Typography>
    </div>
  );
}

export default withStyles(styles)(Loading);