import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Topbar from "../components/TopbarGarnet";
import FooterGarnet from "../components/FooterGarnet";
import Typography from '@material-ui/core/Typography';

const fondoImagenUrl = require("../images/gmobgimg.jpg");
const iconoCentral = require("../images/icono_central.png");

const styles = theme => ({
    tituloPrincipal1: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "48px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "1rem"
    },
    tituloPrincipal2: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "32px",
        lineHeight: "53px",
        color: "#0087C2",
        marginBottom: "3rem"
    },
    textoDetalle: {
        fontFamily: "Source Sans Pro",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        lineHeight: "26px",
        color: "#474747",
        marginBottom: "1.5rem"
    },
    iconoCentral: {
        width: '200px',
        marginLeft: '-100px',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    fondoImagen: {
        background: `url(${fondoImagenUrl}) no-repeat`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: '960px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    containerTab: {
        padding: "10px 7rem 10px 5rem", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
          padding: "10px 1rem 10px 1rem", 
        }
    }
})

class LandingPendiente extends Component{
    constructor(props){
        super(props);
        this.state = {
            currentPath: window.location.host.split('.')[0],
            currentLanding: "https://"+window.location.host,
        }
    }
    toRetry(){

    }
    componentDidMount(){
        console.log(document.querySelector('.mp-mercadopago-checkout-wrapper'));
        while(document.querySelector('.mp-mercadopago-checkout-wrapper')){
            document.querySelector('.mp-mercadopago-checkout-wrapper').remove();
        }
    }
    currentBgImage(){
        console.log(this.state.currentPath );
        if (this.state.currentPath == "opv") {
          return require("../images/bg_opv.png");
          
        }
        else{
          if (this.state.currentPath == "econopticas") {
            return require("../images/bg_eco.png");
            
          }
          else {
            return require("../images/gmobgimg.jpg");
            // return require("../images/bg_eco.png");
          }
        }
      }
    render(){
        const { classes } = this.props;
        return (
            <>
                <Topbar currentPath = {this.state.currentPath} />
                    <Grid container spacing={0}>
                        <Grid  item xs={12} sm={12} md={6}>
                            <div className={classes.containerTab}>
                                <Typography style={{marginTop: "2rem"}} className={classes.tituloPrincipal1}>
                                    Compra pendiente!
                                </Typography>
                                <Typography className={classes.tituloPrincipal2}>
                                    Estás a punto de tener tu producto asegurado.
                                </Typography>
                                <Typography  className={classes.textoDetalle}>
                                    Recibirás en tu correo una copia de la póliza de tu seguro cuando el pago se haya comprobado.
                                    <br></br>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className={classes.fondoImagen} item xs={12} sm={12} md={6}  style={{ backgroundImage:  `url(${this.currentBgImage()})` }} >
                            <img className={classes.iconoCentral} src={iconoCentral} alt="icono"></img>
                        </Grid>
                    </Grid>
                <FooterGarnet />
            </>
        );
    }
}

export default withRouter(withStyles(styles)(LandingPendiente));
